<template>
  <search-header/>
  <div class="container filter-menu">
    <form name="filter" >
    <button type="button" class="btn btn-primary py-2 px-4 d-lg-block" @click="isOpen = !isOpen">Show Filter</button>
    <div class="row select-wrapper mt-4" v-if="isOpen">
      <div v-for="group in searchgroups" :key="group.id"  class="col-md-3">
        <select :name="group.id" @change="useFilter" class="filter-select">
          <option :value="0" selected>Any {{group.name}}</option>
          <option v-for="option in group.attributes" v-bind:value="option.id" :key="option.id">
            {{ option.name }}
          </option>
        </select>
      </div>
    </div>
    </form>
    <div class="row" v-if="cats.length > 0">
      <transition-group name="list">
      <div v-for="cat in cats" :key="cat.id" class="col-md-4 font-jost">
        <div class="text-center">
          <router-link  :to="'/cats/'+ cat.clearname"><img :src="(cat.link)" class="search-img"></router-link>
          <div class="bg-light text-center p-4">
            <h5 class="text-white">{{cat.name}}</h5>
            <p>{{ cat.desc }}</p>
          </div>
        </div>
      </div>
      </transition-group>
    </div>
    <div v-else>
      <div class="col-12 text-center mt-4">
        <div class="alert-danger col-6">Sorry, this cat does not exist.</div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import searchHeader from "@/components/searchHeader";
export default {
  name: 'search',
  components: {
    searchHeader
  },
  data(){
    return {
      isOpen: false,
      value : null,
      baseUrl: this.$baseUrl,
      selected: 0,
      searchgroups: [],
      filters:[],
      cats: [],
      invcats: [],
      endfile: null,
    }
  },
  mounted(){
    this.checkParams()
    this.getNextUser()
    this.getFilter()
  },
  methods:{
    useFilter(e){
      var groupIndex = this.filters.findIndex(element => element.group === e.target.name)
      if(groupIndex >= 0){
        if(e.target.value > 0) {
          this.filters[groupIndex].trait = e.target.value;
        }else{
          this.filters.splice(groupIndex, 1)
        }
      }else {
        this.filters.push({group: e.target.name, trait: e.target.value})
      }
      if(this.filters.length > 0){
      this.axios.post(this.baseUrl + 'api/filtered', {filters: this.filters}).then((response) => {
        this.cats = [];
        this.invcats = [];
        response.data.forEach((value, index) => {
          var clearname = value.name.replace('#', '')
          if(index < 9) {
            this.cats.push({id: value.id, name: value.name, clearname: clearname, desc: value.desc, link: '/img/' + clearname + '.png'})
          }else{
            this.invcats.push({id: value.id, name: value.name, clearname: clearname, desc: value.desc, link: '/img/' + clearname + '.png'})
          }
        })
      })
      }else{
        this.checkParams();
      }
    },
    getFilter(){
      this.axios.get(this.baseUrl + 'api/filter').then((response) => {
        response.data.forEach((value) => {
          this.searchgroups.push(value);
        })
      })
    },
    checkParams(){
      this.value = this.$route.params;
      this.cats = [];
      this.invcats = [];
      if(this.value.key > 0){
        this.axios.post(this.baseUrl + 'api/search', {name: this.value.key}).then((response) => {
          console.log(response.data)
          var clearname = response.data.name.replace('#', '')
          this.cats.push({id: response.data.id, name: response.data.name, clearname: clearname, desc: response.data.desc, link: '/img/' + clearname + '.png'})
          console.log(this.cats)
        })
      }else {
        this.axios.get(this.baseUrl + 'api/first').then((response) => {
          response.data.forEach((value, index) => {
            var clearname = value.name.replace('#', '')
            if(index < 9) {
              this.cats.push({id: value.id, name: value.name, clearname: clearname, desc: value.desc, link: '/img/' + clearname + '.png'})
            }else{
              this.invcats.push({id: value.id, name: value.name, clearname: clearname, desc: value.desc, link: '/img/' + clearname + '.png'})
            }
          })
       })
      }
    },
    getNextUser() {
      window.onscroll = () => {
        console.log(this.endfile)
        console.log(this.invcats)
        if(this.endfile === null) {
          let offsetHeight = document.documentElement.offsetHeight * 0.8;
          let scrollHeight = document.documentElement.scrollTop + window.innerHeight;
          if (scrollHeight > offsetHeight) {
            if(this.invcats.length > 0) {
              this.cats.push(this.invcats[0]);
              this.invcats.shift()
            }else{
              this.endfile = 1;
            }
          }
        }else{
          if(this.invcats.length > 0){
            this.endfile = null;
          }
        }
      }
    }
  },
  watch: {
    '$route.params': 'checkParams',
  },
}
</script>
<style>
.list-item {
  display: inline-block;
  margin-right: 10px;
}

.list-enter-active,
.list-leave-active {
  transition: all 1s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
