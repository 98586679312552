<template>
  <!-- Header Start -->
  <div class="jumbotron jumbotron-fluid page-header position-relative overlay-bottom" style="margin-bottom: 90px;">
    <div class="container text-center py-5">
      <h1 class="text-white display-1">Giveaways</h1>
    </div>
  </div>

  <!-- Feature Start -->

  <div class="container-fluid py-5">
    <div class="container py-5">
      <div class="row">
        <div class="row mx-0 justify-content-center pt-5">

          <div class="col-lg-12">
            <div class="section-title text-center position-relative mb-4">
              <h6 id="two" class="d-inline-block position-relative text-secondary text-uppercase pb-2">Gifts</h6>
              <h1 class="display-4">Airdrops &amp; Giveaways</h1>
            </div>
            <p class="mb-4 pb-2 text-center"> Because this project is a matter of the heart for us, we would like to give you small gifts on a regular basis. Do not miss the opportunity to get your present. You can find regular giveaways on <a href="https://twitter.com/pumpkin_catz"> twitter</a> and <a href="https://discord.gg/xCzRmnUPc5"> discord.</a> Some presents will be airdropped into holders wallets.
            </p>

          </div>
        </div>
        <div class="container-fluid px-0">
          <div class="row mx-0 justify-content-center pt-5">
            <div class="container-fluid">
              <div class="container py-5">
                <carousel :items-to-show="3"  :wrapAround="true" :autoplay="2000" :pauseAutoplayOnHover="true" :breakpoints="breakpoints">
                  <slide v-for="(image) in first_images" :key="image.id">
                    <div class="team-item">
                      <img :src="getImage(image.url)"  class="img-fluid w-100 m-1 mb-2 px-2"/>
                      <div class="bg-light text-center p-4">
                        <h5 class="mb-3">{{ image.name }}</h5>
                        <p class="mb-2">{{ image.text }}</p>
                      </div>
                    </div>
                  </slide>

                  <template #addons>
                    <navigation />
                    <pagination />
                  </template>
                </carousel>
      </div>
    </div>
          </div>
        </div>
      </div>
    </div>
    </div>


  <!-- Feature Start -->
</template>
<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
  name: 'App',
  inject:['baseUrl'],
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      breakpoints: {
        // 700px and up
        0: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
      },
      first_images: [
        {id: 1,url:'/img/comic1.jpg', name: 'Comic 1', text:''},
        {id: 2,url:'/img/comic2.gif', name: 'Comic 2', text:''},
        {id: 3,url:'/img/comic3.jpg', name: 'Comic 3', text:''},
        {id: 4,url:'/img/comic4.jpg', name: 'Comic 4', text:''},
        {id: 5,url:'/img/comic5.jpg', name: 'Comic 5', text:''},
        {id: 6,url:'/img/comic6.jpg', name: 'Comic 6', text:''},
      ],
    }
  },
  mounted() {
    console.log('Url' + this.$baseUrl) // 'bar'
  },
  methods: {
    getImage(imagePath) {
      return imagePath;
    }
  }
};
</script>
<style>



</style>
