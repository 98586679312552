<template>
  <div className="container">

  <catsdetail></catsdetail>
  </div>
</template>

<script>
// @ is an alias to /src
import catsdetail from "@/components/catsdetail";

export default {
  name: 'search',
  components: {
    catsdetail
  },
  data() {
    return {
      value: null,
      last: null,
      next: null,
      baseUrl: this.$baseUrl,
      cat: [],
      attribute: [],
    }
  },
  mounted() {
    this.checkParams()
  },
  methods: {
    checkParams() {
      this.value = this.$route.params.name;
    }
  },
  watch: {
    '$route.params': 'checkParams',
  }
}
</script>
