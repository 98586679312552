<template>
  <!-- Header Start -->
  <div class="jumbotron jumbotron-fluid position-relative overlay-bottom" style="margin-bottom: 90px;">
    <div class="container text-center my-5 py-5">
      <h1 class="text-white mt-4 mb-4">Find your PumpkinCatz&nbsp;</h1>
      <h1 class="text-white display-1 mb-5">Cat</h1>
      <div class="mx-auto mb-5" style="width: 100%; max-width: 600px;">
        <div class="input-group">
          <div class="input-group-prepend">
            <button class="btn btn-outline-light bg-white text-body px-4 dropdown-toggle" type="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">Filters</button>
            <div class="dropdown-menu">
              <a class="dropdown-item" href="#">Courses 1</a>
              <a class="dropdown-item" href="#">Courses 2</a>
              <a class="dropdown-item" href="#">Courses 3</a>
            </div>
          </div>
          <input type="text" class="form-control border-light" style="padding: 30px 25px;" placeholder="#">
          <div class="input-group-append">
            <button class="btn btn-secondary px-4 px-lg-5">Search</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Header End -->


  <!-- About Start -->
  <div class="container-fluid py-5">
    <div class="row mx-0 justify-content-center pt-5">
      <div class="col-lg-6">
        <div class="section-title text-center position-relative mb-4">
          <h6 class="d-inline-block position-relative text-secondary text-uppercase pb-2">Rarity </h6>
          <h1 class="display-4">First Drop</h1>
          <img src="@/img/Table_PumpkinCatz.jpg" class="img-table-rarity"/></div>
      </div>

    </div>
  </div>

  <div class="row mx-0 justify-content-center pt-5">
    <div class="col-lg-6">
      <div class="section-title text-center position-relative mb-4">
        <h6 class="d-inline-block position-relative text-secondary text-uppercase pb-2">Rarity </h6>
        <h1 class="display-4">Halloween Special</h1>
        <img src="@/img/Table_PumpkinCatz_Halloween.jpg" class="img-table-rarity"/></div>

    </div>
  </div>

  <!-- About End -->

</template>
<script>

</script>
<style>



</style>
