<template>
  <!-- Header Start -->
  <div class="jumbotron jumbotron-fluid page-header position-relative overlay-bottom" style="margin-bottom: 90px;">
    <div class="container text-center py-5">
      <h1 class="text-white display-1">Donations</h1>
      <!--  <div class="d-inline-flex text-white mb-5">
           <p class="m-0 text-uppercase"><a class="text-white" href="">Home</a></p>
           <i class="fa fa-angle-double-right pt-1 px-3"></i>
           <p class="m-0 text-uppercase">About</p>
       </div>  End
       <div class="mx-auto mb-5" style="width: 100%; max-width: 600px;">
           <div class="input-group">
               <div class="input-group-prepend">
                   <button class="btn btn-outline-light bg-white text-body px-4 dropdown-toggle" type="button" data-toggle="dropdown"
                       aria-haspopup="true" aria-expanded="false">Filters</button>
                   <div class="dropdown-menu">
                       <a class="dropdown-item" href="#">Courses 1</a>
                       <a class="dropdown-item" href="#">Courses 2</a>
                       <a class="dropdown-item" href="#">Courses 3</a>
                   </div>
               </div>
               <input type="text" class="form-control border-light" style="padding: 30px 25px;" placeholder="#">
               <div class="input-group-append">
                   <button class="btn btn-secondary px-4 px-lg-5">Search</button>
               </div>
           </div>
       </div>-->
    </div>
  </div>
  <!-- Feature Start -->

  <div class="container-fluid py-5">
    <div class="container py-5">
      <div class="row">
        <div class="col-lg-7 mb-5 mb-lg-0">
          <div class="section-title position-relative mb-4">
            <h6 class="d-inline-block position-relative text-secondary text-uppercase pb-2">Charity</h6>
            <h1 class="display-4">Donations</h1>
          </div>
          <p class="mb-4 pb-2">From the very beginning when we got the idea of doing a NFT project in honor of our beloved cat Pumpkin we were convinced that we have to use some of the raised funds to donate to charities which care about shelterless cats (and pets).

            A minimum of 10 % of the revenue will always be allocated to a donation wallet – we set aside 1000 ADA from the initial drop and 200 ADA from the Halloween Special.

            The ₳DA that have not yet been donated are staked at <a href="https://www.hazelpool.com" target="_blank">HAZEL Stakepool</a> until the donations are handed over.</p>
          <div class="d-flex mb-3">
            <div class="btn-icon bg-primary mr-4 text-white font-size-2">
              <font-awesome-icon :icon="['fa', 'star']" />
            </div>
            <div class="mt-n1">
              <h4>First Donation</h4>
              <p>The first charity-project we donated to is „Jardinet dels Gats“, an organization aiming to help shelterless cats in the city center of Barcelona. We have already visited the site a few years ago and we were impressed by the amazing job they are doing! We visited them on Sept. 30th 2021 and handed over the first donation of 700 € (=810 USD, 385 ₳ at that time).
                The cat in the photo on the right is called Lito and takes over the donation. A generous PumpkinCatz supporter exchanged 333 ₳ for the 700 Euros so that we were able to hand over even more than expected. The organization used the donation to buy cat food and pay ongoing expenses. Thank you for making this possible!</p>
            </div>
          </div>
        </div>
        <div class="col-lg-5" style="min-height: 500px;">
          <div class="position-relative h-100">
            <img class="position-absolute w-100 h-100" src="img/feature.jpg" style="object-fit: cover;">
          </div>
        </div>




        <div class="container-fluid px-0 py-5">
          <div class="row mx-0 justify-content-center pt-5">
            <div class="container-fluid py-5">
              <div class="container py-5">
                <carousel :items-to-show="3"  :wrapAround="true" :autoplay="2000" :pauseAutoplayOnHover="true" :breakpoints="breakpoints">
                  <slide v-for="(image) in first_images" :key="image.id">
                    <div class="team-item">
                      <img :src="getImage(image.url)"  class="img-fluid w-100 m-1 mb-2 px-2"/>
                      <div class="bg-light text-center p-4">
                        <h5 class="mb-3">{{ image.name }}</h5>
                        <p class="mb-2">{{ image.text }}</p>
                      </div>
                    </div>
                  </slide>

                  <template #addons>
                    <navigation />
                    <pagination />
                  </template>
                </carousel>
      </div>
    </div>
          </div>
        </div>
      </div>
    </div>
    </div>


  <!-- Feature Start -->
</template>
<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
  name: 'App',
  inject:['baseUrl'],
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      breakpoints: {
        // 700px and up
        0: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
      },
      first_images: [
        {id: 1,url:'/img/jadinet1.jpg', name: 'Jardinet dels Gats', text:'Barcelona / Spain'},
        {id: 2,url:'/img/jadinet2.jpg', name: 'Jardinet dels Gats', text:'Barcelona / Spain'},
        {id: 3,url:'/img/jadinet3.jpg', name: 'Jardinet dels Gats', text:'Barcelona / Spain'},
        {id: 4,url:'/img/jadinet4.jpg', name: 'Jardinet dels Gats', text:'Barcelona / Spain'},
        {id: 5,url:'/img/jadinet5.jpg', name: 'Jardinet dels Gats', text:'Barcelona / Spain'},
        {id: 6,url:'/img/jadinet6.jpg', name: 'Jardinet dels Gats', text:'Barcelona / Spain'},
        {id: 7,url:'/img/jadinet7.jpg', name: 'Jardinet dels Gats', text:'Barcelona / Spain'},
        {id: 8,url:'/img/jadinet8.jpg', name: 'Jardinet dels Gats', text:'Barcelona / Spain'},
        {id: 9,url:'/img/jadinet9.jpg', name: 'Jardinet dels Gats', text:'Barcelona / Spain'},
      ],
    }
  },
  mounted() {
    console.log('Url' + this.$baseUrl) // 'bar'
  },
  methods: {
    getImage(imagePath) {
      return imagePath;
    }
  }
};
</script>
<style>



</style>
