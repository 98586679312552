<template>
  <!-- Header Start -->
  <div class="jumbotron jumbotron-fluid page-header position-relative overlay-bottom" style="margin-bottom: 90px;">
    <div class="container text-center py-5">
      <h1 class="text-white display-1">About</h1>
      <!--  <div class="d-inline-flex text-white mb-5">
           <p class="m-0 text-uppercase"><a class="text-white" href="">Home</a></p>
           <i class="fa fa-angle-double-right pt-1 px-3"></i>
           <p class="m-0 text-uppercase">About</p>
       </div>  End
       <div class="mx-auto mb-5" style="width: 100%; max-width: 600px;">
           <div class="input-group">
               <div class="input-group-prepend">
                   <button class="btn btn-outline-light bg-white text-body px-4 dropdown-toggle" type="button" data-toggle="dropdown"
                       aria-haspopup="true" aria-expanded="false">Filters</button>
                   <div class="dropdown-menu">
                       <a class="dropdown-item" href="#">Courses 1</a>
                       <a class="dropdown-item" href="#">Courses 2</a>
                       <a class="dropdown-item" href="#">Courses 3</a>
                   </div>
               </div>
               <input type="text" class="form-control border-light" style="padding: 30px 25px;" placeholder="#">
               <div class="input-group-append">
                   <button class="btn btn-secondary px-4 px-lg-5">Search</button>
               </div>
           </div>
       </div>-->
    </div>
  </div>
  <!-- Header End -->


  <!-- About Start -->
  <div class="container-fluid py-5">
    <div class="container py-5">
      <div class="row">
        <div class="col-lg-5 mb-5 mb-lg-0" style="min-height: 420px;">
          <div class="position-relative h-100">
            <img class="position-absolute w-100 h-100" src="@/img/about.jpg" style="object-fit: cover;">
          </div>
        </div>
        <div class="col-lg-7">
          <div class="section-title position-relative mb-4">
            <h6 class="d-inline-block position-relative text-secondary text-uppercase pb-2">About Us</h6>
            <h1 class="display-4">PumpkinCatz</h1>
          </div>
          <p class="mb-4 pb-2 text-mobile-center">is a collection of unique collectable cat portraits, as NFTs on the Cardano Blockchain. The project is dedicated to our beloved cat and best friend – Pumpkin. 500 first drop, 111 Halloween special.
            Pumpkin was a little sunshine but he also has a strong personality. He was a very special cat. These portraits are made in loving memory of our PumpkinCat, who we miss a lot.
            We spent a lot of time conceptualizing the project and the design, and we created beautiful cats, unique accessories and toys to honor the amazing Cardano community and celebrate the diversity within the cryptocurrency community.
            As the Cardano ecosystem develops and with the rise of smart contracts we intend to increase the utility of our tokens to give members exclusive access and bonuses.</p>
          <div class="row pt-3 mx-0">
            <div class="col-md-3 px-0">
              <div class="bg-success text-center p-4">
                <h1 class="text-white" data-toggle="counter-up"> <vue3-autocounter ref='counter' :startAmount='0' :endAmount='500' :duration='3' separator=' ' decimalSeparator='.' :decimals='0' :autoinit='true' /></h1>
                <h6 class="text-uppercase text-white">First<span class="d-block">Drop</span></h6>
              </div>
            </div>
            <div class="col-md-3 px-0">
              <div class="bg-primary text-center p-4">
                <h1 class="text-white" data-toggle="counter-up"><vue3-autocounter ref='counter' :startAmount='0' :endAmount='111' :duration='3' separator=' ' decimalSeparator='.' :decimals='0' :autoinit='true' /></h1>
                <h6 class="text-uppercase text-white">Halloween<span class="d-block">Special</span></h6>
              </div>
            </div>
            <div class="col-md-3 px-0">
              <div class="bg-secondary text-center p-4">
                <h1 class="text-white" data-toggle="counter-up"><vue3-autocounter ref='counter' :startAmount='0' :endAmount='1000' :duration='3' separator=' ' decimalSeparator='.' :decimals='0' :autoinit='true' /></h1>
                <h6 class="text-uppercase text-white">Ada<span class="d-block">Donations</span></h6>
              </div>
            </div>
            <div class="col-md-3 px-0">
              <div class="bg-warning text-center p-4">
                <h1 class="text-white" data-toggle="counter-up"><vue3-autocounter ref='counter' :startAmount='0' :endAmount='333' :duration='3' separator=' ' decimalSeparator='.' :decimals='0' :autoinit='true' /></h1>
                <h6 class="text-uppercase text-white">Ada<span class="d-block">donated</span></h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- About End -->


  <!-- Feature Start -->

  <div class="container-fluid px-0 py-5">
    <div class="row mx-0 justify-content-center pt-5">
      <div class="col-lg-6">
        <div class="section-title text-center position-relative mb-4">
          <h6 id="two" class="d-inline-block position-relative text-secondary text-uppercase pb-2">First Drop</h6>
          <h1 class="display-4">The Beginning</h1>
        </div>
        <p class="mb-4 pb-2 text-center">The first 500 PumpkinCatz are already sold out, they were available to mint for 18 ₳DA. Every single PumpkinCat can only be minted once, some will happen to have very rare traits – like the pumpkin border. Our ambition is to build an active community across all relevant CNFT platforms and bring attention to the Cardano Ecosystem. But also to do something good – 10 % of the revenue collected will be donated to help shelterless cats. One donation has already been handed over. On "<router-link to="/donations">Donations</router-link>" we report about our trip to Barcelona, where we handed over 700 € (=810 USD, 385 ₳ at that time) to the organization "Jardinet dels Gats". With your purchase you enable us to support further projects. </p>


      </div></div>


    <div class="container-fluid py-5">
      <div class="container py-5">
        <carousel :items-to-show="3" :wrapAround="true" :autoplay="2000" :pauseAutoplayOnHover="true" :breakpoints="breakpoints">
          <slide v-for="(image) in first_images" :key="image.id">
            <div class="team-item">
              <img :src="getImage(image.url)"  class="img-fluid w-100"/>
              <div class="bg-light text-center p-4">
                <h5 class="mb-3">#{{ image.name }}</h5>
              </div>
            </div>
          </slide>

          <template #addons>
            <navigation />
            <pagination />
          </template>
        </carousel>
        <div class="container-fluid px-0 py-5">
          <div class="row mx-0 justify-content-center pt-5">
            <div class="col-lg-6">
              <div class="section-title text-center position-relative mb-4">
                <h6 id="two" class="d-inline-block position-relative text-secondary text-uppercase pb-2">Specials</h6>
                <h1 class="display-4">Halloween Special</h1>
              </div></div>
            <p class="mb-4 pb-2 text-center">On Oct. 31st 2021 we released our Halloween Special. 100 spooky PumpkinCatz were available to mint for 20 ADA. We put again more than 10 % of the revenue aside to donate it to help shelterless cats. Find more information on "<router-link to="/donations">Donations</router-link>". Stay tuned for further specials and don't forget that with your purchase you help us to support more charities.
              Mint one <a href="https://www.pumpkincatz.site/#buy" target="_blank"> here</a>.</p>


          </div>


          <div class="container-fluid py-5">
            <div class="container py-5">
              <carousel :items-to-show="3" :wrapAround="true" :autoplay="2000" :pauseAutoplayOnHover="true" :breakpoints="breakpoints">
                <slide v-for="(image) in halloween_images" :key="image.id">
                  <div class="team-item">
                    <img :src="getImage(image.url)"  class="img-fluid w-100"/>
                    <div class="bg-light text-center p-4">
                      <h5 class="mb-3">#{{ image.name }}</h5>
                    </div>
                  </div>
                </slide>

                <template #addons>
                  <navigation />
                  <pagination />
                </template>
              </carousel>
            </div>
          </div>
        </div>
        <div class="container-fluid px-0 py-5">
          <div class="row mx-0 justify-content-center pt-5">
            <div class="col-lg-6">
              <div class="section-title text-center position-relative mb-4">
                <h6 id="three" class="d-inline-block position-relative text-secondary text-uppercase pb-2">Specials</h6>
                <h1 class="display-4">Holiday Special</h1>
              </div></div>
            <p class="text-center">As a small gift for Christmas we airdropped some festive PumpkinCatz to holders and gave some away in our Discord.</p>


          </div>


          <div class="container-fluid py-5">
            <div class="container py-5">
              <carousel :items-to-show="3" :wrapAround="true" :autoplay="2000" :pauseAutoplayOnHover="true" :breakpoints="breakpoints">
                <slide v-for="(image) in holiday_images" :key="image.id">
                  <div class="team-item">
                    <img :src="getImage(image.url)"  class="img-fluid w-100"/>
                    <div class="bg-light text-center p-4">
                      <h5 class="mb-3">#{{ image.name }}</h5>
                    </div>
                  </div>
                </slide>

                <template #addons>
                  <navigation />
                  <pagination />
                </template>
              </carousel>
            </div>
          </div>
        </div>
      </div>
    </div></div>

  <!-- Feature Start -->
</template>
<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
  name: 'App',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      breakpoints: {
        // 700px and up
        0: {
          itemsToShow: 1,
          snapAlign: 'center',
        },
        // 1024 and up
        1024: {
          itemsToShow: 3,
          snapAlign: 'start',
        },
      },
      first_images: [
        {id: 1,url:'/img/pumpkincatz2.png', name: '2'},
        {id: 2,url:'/img/pumpkincatz98.png', name: '98'},
        {id: 3,url:'/img/pumpkincatz164.png', name: '164'},
        {id: 4,url:'/img/pumpkincatz233.png', name: '233'},
        {id: 5,url:'/img/pumpkincatz414.png', name: '414'},
        {id: 6,url:'/img/pumpkincatz498.png', name: '498'},
      ],
      halloween_images: [
        {id: 1,url:'/img/600.png', name: '600'},
        {id: 2,url:'/img/501.png', name: '501'},
        {id: 3,url:'/img/513.png', name: '513'},
        {id: 4,url:'/img/530.png', name: '530'},
        {id: 5,url:'/img/552.png', name: '552'},
        {id: 6,url:'/img/578.png', name: '578'},
      ],
      holiday_images: [
        {id: 1,url:'/img/612.png', name: '612'},
        {id: 2,url:'/img/616.png', name: '616'},
        {id: 3,url:'/img/626.png', name: '626'},
        {id: 4,url:'/img/630.png', name: '630'},
        {id: 5,url:'/img/640.png', name: '640'},
        {id: 6,url:'/img/644.png', name: '644'},
      ]
    }
  },
  mounted() {
  },
  methods: {
    getImage(imagePath) {
      return imagePath;
    }
  }
};
</script>
<style>



</style>
