<template>
  <!-- Header Start -->
  <div class="jumbotron jumbotron-fluid page-header position-relative overlay-bottom" style="margin-bottom: 90px;">
    <div class="container text-center py-5">
      <h1 class="text-white display-1">FAQ</h1>
    </div>
  </div>
  <!-- Header End -->


  <div class="container-fluid py-5">
    <div class="container py-5">
      <div class="section-title text-center position-relative mb-5">
        <h6 class="d-inline-block position-relative text-secondary text-uppercase pb-2">???</h6>
        <h1 class="display-4">FAQ</h1>
      </div>

      <div class="mt-n1 text-center">
        <h4>When was the Halloween drop?</h4>

        <p>Our limited Halloween Special was on Oct. 31st, 4 pm UTC.</p>
      </div><br>


      <div class="mt-n1 text-center">
        <h4>When was the first drop?</h4>

        <p>Our first drop was on Monday, Sept. 13th, 20.00 UTC, all 500 NFTs sold out in 40 minutes.</p>
      </div><br>


      <div class="mt-n1 text-center">
        <h4>Will there be additional drops?</h4>

        <p>Yes, we plan on release limited seasonal batches.</p>
      </div><br>

      <div class="mt-n1 text-center">
        <h4>What's the mint price?</h4>

        <p>20 ₳ for the Halloween Special.<br>18 ₳ for the first drop</p>

      </div><br>

      <div class="mt-n1 text-center">
        <h4>When will I get my PumpkinCat? </h4>

        <p>After you send the funds to the wallet, it will be minted and sent to your wallet. <br>This should be done in several minutes in most cases. During peak periods, it could take longer.</p>
      </div><br>

      <div class="mt-n1 text-center">
        <h4>Which PumpkinCat is the rarest? </h4>

        <p>Check "Rarity Table" in the menu.</p>
      </div><br>

      <div class="mt-n1 text-center">
        <h4>What wallet can I use? </h4>

        <p>Only use a Cardano Shelley-era wallet like Yoroi, Daedalus, Adalite or Nami. Do not use an exchange, your NFT will be lost forever.</p>

      </div><br>

      <div class="mt-n1 text-center">
        <h4>What happens if I send the wrong amount? </h4>

        <p>A refund will arrive in your wallet for the amount with the transaction fee subtracted. To avoid this, you need to send the exact amount.</p>
      </div><br>

      <div class="mt-n1 text-center">
        <h4>Are there any limits?  </h4>

        <p>You will be able to mint 1 NFTs at once. Make sure to send the exact amount. Sending more funds will initiate a refund.</p>
      </div><br>

      <div class="mt-n1 text-center">
        <h4>Why isn't my refund the full amount?  </h4>

        <p>We have to subtract the transaction fee (approximately 0.18 - 0.20 ADA) when we are processing the refund.</p>

      </div><br>



      <div class="mt-n1 text-center">
        <h4>What's the policy ID?  </h4>

        <p>e1466104b6a0e607d8d94b8ffbfa406da00e59e87f458d3e8d9b187a</p>
      </div><br>

      <div class="mt-n1 text-center">
        <h4>Is your project verified on CNFT.io/Tokhun.io?</h4>

        <p>Yes, always double check the policy ID to be 100 % safe</p>

      </div>

    </div>


  </div>
</template>
<script>

</script>
<style>



</style>
