<template>
  <div class="row">
    <div class="col-4 text-center"><a v-if="this.last > 0" href="#" @click="lastPage()">Previous</a></div>
    <div class="col-4 text-center"><a :href="'/search/'">Overview</a></div>
    <div class="col-4 text-center"><a v-if="this.next < 651" href="#" @click="nextPage()">Next</a> </div>
  </div>
  <div class="row font-jost">
      <div class="row">
        <div class="col-12 text-center pt-4">
          <h2>{{cat.name}}</h2>
          <p v-if="cat.rank">Rank: {{cat.rank}} of 500</p>
        </div>
        <div class="col-12 text-center">
          <div class="row">
            <div class="col-md-4"><img class="w-100" :src="'/img/' + value + '.png'"></div>
            <div class="col-md-8 pt-3">
              <div class="mx-4">
                <div class="row">
                  <div class="col-4 text-left font-weight-bold">Edition:</div>
                  <div class="col-8 text-left">{{cat.desc}}</div>
                </div>
                <div v-for="attr in attribute" :key="attr.id">
                  <div v-for="(att,key) in attr" :key="att.id">
                    <div class="row">
                      <div class="col-4 text-left font-weight-bold">{{key}}</div>
                      <div class="col-8 text-left">{{att}}</div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "catsdetail",
  data() {
    return {
      value: this.$route.params.name,
      last: Number(this.$route.params.name) - 1,
      next: Number(this.$route.params.name) + 1,
      clearname: null,
      baseUrl: this.$baseUrl,
      cat: [],
      attribute: [],
    }
  },
  mounted() {
    this.currentPage()
  },
  methods: {
    lastPage() {
      this.axios.post(this.baseUrl + 'api/detail', {name: this.last}).then((response) => {
        this.cat = response.data[0];
        this.attribute = response.data[1];
      })
      this.value = this.last
      this.last--
      this.next--
    },
    nextPage() {
      this.axios.post(this.baseUrl + 'api/detail', {name: this.next}).then((response) => {
        this.cat = response.data[0];
        this.attribute = response.data[1];
      })
      this.value = this.next
      this.last++
      this.next++
    },
    currentPage() {
      this.axios.post(this.baseUrl + 'api/detail', {name: this.value}).then((response) => {
        console.log(response.data)
        this.cat = response.data[0];
        this.attribute = response.data[1];
      })
    }
  }
}
</script>

<style scoped>

</style>
